@import "../../mixins";

.footer {
  background-color: $grey--light;
  border-bottom: 80px solid $blue;
  color: $grey--dark;
  padding: 134px 20px 88px;
  text-align: center;

  &__heading {
    @include headline;
  }
}
