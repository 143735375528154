@import '../../mixins';

.chat {
  height: var(--vh);
  max-height: 823px;
  position: relative;
  z-index: 0;

  &:-webkit-full-screen {
    position: static;
  }

  @include medium {
    padding-top: 56.2%;
    max-height: 674px;
    height: calc(var(--vh) - 98px);
  }

  @include large {
    padding-top: 56.2%;
    height: auto;
    max-height: none;
  }

  iframe {
    border: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @include defaultPlusXL {
    height: calc(100vh - 98px);
  }
}
