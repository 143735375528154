@import '../../mixins';

.header {
  position: relative;
  height: 98px;
  z-index: 3;

  &__inner {
    width: 100%;
    max-width: 1200px;
    background-color: $white;
    align-content: center;
    display: flex;
    justify-content: space-between;
    padding: 30px 20px 27px;

    border-bottom: 1px solid $grey--light;

    @include medium {
      position: fixed;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &__logo {
    align-items: center;
    display: flex;
    max-width: 50%;

    img {
      max-width: 100%;
      width: 365px;

      @include large {
        margin-left: 17px;
      }
    }
  }

  &__tools {
    align-items: center;
    display: flex;

    a {
      margin-left: 10px;
      width: 36px;

      @include medium {
        margin-left: 40px;
      }

      &:first-child {
        margin: 0;
      }

      svg {
        color: $blue--light;
        display: block;
        font-size: 40px;
      }
    }
  }
}
