@import '../../mixins';

.teasers {
  padding: 60px 20px 60px;

  @include large {
    padding-left: 0;
    padding-right: 0;
  }

  &__heading {
    @include headline;

    margin-bottom: 40px;
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 60px;
    grid-column-gap: 30px;

    @include medium {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__more {
    font-size: 28px;
    line-height: 32px;
    margin: 0 0 60px;
    display: flex;
    justify-content: center;
    color: #4a4a4a;
    letter-spacing: 0.32px;
    cursor: pointer;

    span {
      align-self: center;
      margin-right: 5px;
    }
  }
}

.teaser {
  &__image {
    display: block;
    width: 100%;
  }

  &__heading {
    color: $grey--dark;
    font-size: 24px;
    letter-spacing: 0.27px;
    line-height: 28px;
    margin: 28px 0 16px;
  }

  &__content {
    display: flex;
    color: $grey--dark;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.23px;
  }

  &__link {
    flex: 0 0 auto;
    color: $white;
    background-color: $blue;
    width: 60px;
    height: 60px;
    display: flex;
    align-content: center;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: block;
      font-size: 36px;
    }
  }
}
