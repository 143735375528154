@import "../../mixins";

.search {
  background-color: $orange;
  color: $white;
  text-align: center;
  padding: 60px 20px 100px;

  &__fieldset {
    margin: 0 auto;
    position: relative;
    max-width: 544px;
    width: calc(100% - 40px);
  }

  &__input {
    @include headline;

    border: 0 none;
    display: block;
    height: 80px;
    width: 100%;
    text-indent: 20px;
    padding: 0 60px 0 0;
  }

  &__btn {
    background-color: transparent;
    padding: 0;
    border: 0;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(0, -50%);

    svg {
      display: block;
      color: $blue--light;
      font-size: 40px;
    }
  }

  &__heading {
    @include headline;

    color: $white;
    margin-bottom: 40px;
  }
}
