@import 'mixins';

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font: 400 16px/18px $family-sans-serif;
  margin: 0;
}
