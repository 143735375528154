$family-sans-serif: Arial, sans-serif;
$blue: #196bbe;
$blue--light: #1662af;
$orange: #f60;
$white: #fff;
$grey--light: #eaeaea;
$grey--dark: #4a4a4a;

@mixin headline {
  font-size: 32px;
  line-height: 37px;
  letter-spacing: 0.36px;
  color: $grey--dark;
}

@mixin large {
  @media (min-width: 1220px) {
    @content;
  }
}

@mixin medium {
  @media (min-width: 768px) and (min-height: 500px) {
    @content;
  }
}

@mixin defaultPlusXL {
  @media (max-width: 767px) {
    @content;
  }

  @media (max-width: 1023px) and (max-height: 500px) {
    @content;
  }
}
